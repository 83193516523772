import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "homeWrap" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RouterView = _resolveComponent("RouterView")!
  const _component_TabbarItem = _resolveComponent("TabbarItem")!
  const _component_Tabbar = _resolveComponent("Tabbar")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_RouterView),
    _createVNode(_component_Tabbar, {
      route: "",
      "active-color": "#1296db"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_TabbarItem, {
          replace: "",
          to: "/chat",
          icon: "chat-o"
        }, {
          default: _withCtx(() => [
            _createTextVNode("聊天")
          ]),
          _: 1
        }),
        _createVNode(_component_TabbarItem, {
          replace: "",
          to: "/contact",
          icon: "friends-o"
        }, {
          default: _withCtx(() => [
            _createTextVNode(" 联系人 ")
          ]),
          _: 1
        }),
        _createVNode(_component_TabbarItem, {
          replace: "",
          to: "/user",
          icon: "user-o"
        }, {
          default: _withCtx(() => [
            _createTextVNode("我的")
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ]))
}