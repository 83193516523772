
import { Options, Vue } from "vue-class-component";
import { RouterView } from "vue-router";
import { Tabbar, TabbarItem } from "vant";

@Options({
  components: {
    RouterView,
    Tabbar,
    TabbarItem
  }
})
export default class Home extends Vue {}
